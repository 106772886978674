<template>
    <div id="dashboard">
        <div class="row">
            <div class="col-sm-12 col-lg-2">
                <table class="table table-sm table-striped" style="border: 1px solid gold;">
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">四星計數機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">次數</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="probability_data in dashboard_data.probability" :key="probability_data.type">
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.type }}
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.count }} 次
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">四星0123間隔機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">間隔</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(analysis_data, index) in dashboard_data.analysis_datas" :key="'counter_' + index">
                            <td style="text-align: left;font-size: 14px;">
                                0123
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ analysis_data.counter }} 期
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ analysis_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-12 col-lg-2">
                <table class="table table-sm table-striped" style="border: 1px solid gold;">
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">三星計數機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">次數</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="type-tr" @click="type_tr_click($event)" v-for="probability_data in dashboard_data.thirteen_probability" :key="'thirteen_counter_probability_' + probability_data.counter + '_' + probability_data.type" v-bind:data-type="probability_data.type">
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.type.split("").join(" / ") }}
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.count }} 次
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">三星立三柱間隔機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">間隔</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="type-tr" @click="type_tr_click($event)" v-for="thirteen_analysis_data in dashboard_data.thirteen_analysis_datas" :key="'thirteen_counter_' + thirteen_analysis_data.counter + '_' + thirteen_analysis_data.type" v-bind:data-type="thirteen_analysis_data.type">
                            <td style="text-align: left;font-size: 14px;">
                                {{ thirteen_analysis_data.type.split("").join(" / ") }}
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ thirteen_analysis_data.counter }} 期
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ thirteen_analysis_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-12 col-lg-2">
                <table class="table table-sm table-striped" style="border: 1px solid gold;">
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">二星計數機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">次數</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="type-tr" @click="type_tr_click($event)" v-for="probability_data in dashboard_data.twenty_probability" :key="'twenty_counter_probability_' + probability_data.counter + '_' + probability_data.type" v-bind:data-type="probability_data.type">
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.type.split("").join(" / ") }}
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.count }} 次
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ probability_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="3" style="background-color: gold;">二星立二柱間隔機率</th>
                        </tr>
                        <tr>
                            <th colspan="3">總數：{{ dashboard_data.total_count }}</th>
                        </tr>
                        <tr>
                            <th style="text-align: left;font-size: 14px;">類型</th>
                            <th style="text-align: left;font-size: 14px;">間隔</th>
                            <th style="text-align: left;font-size: 14px;">機率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="type-tr" @click="type_tr_click($event)" v-for="twenty_analysis_data in dashboard_data.twenty_analysis_datas" :key="'twenty_counter_' + twenty_analysis_data.counter + '_' + twenty_analysis_data.type" v-bind:data-type="twenty_analysis_data.type">
                            <td style="text-align: left;font-size: 14px;">
                                {{ twenty_analysis_data.type.split("").join(" / ") }}
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ twenty_analysis_data.counter }} 期
                            </td>
                            <td style="text-align: left;font-size: 14px;">
                                {{ twenty_analysis_data.probability }}%
                            </td>
                        </tr>
                    </tbody>
                </table>
                <a @click="load_data(90)" style="margin: 5px;" class="btn btn-sm btn-warning">90天</a>
                <a @click="load_data(180)" style="margin: 5px;" class="btn btn-sm btn-warning">180天</a>
                <a @click="load_data(365)" style="margin: 5px;" class="btn btn-sm btn-warning">1年</a>
                <a @click="load_data(730)" style="margin: 5px;" class="btn btn-sm btn-warning">2年</a>
                <a @click="load_data(1095)" style="margin: 5px;" class="btn btn-sm btn-warning">3年</a>
                <a @click="load_data('All')" style="margin: 5px;" class="btn btn-sm btn-warning">全部</a>
            </div>
            <div class="col-sm-12 col-lg-6">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>期<br>日期</th>
                                <th>四星<br>0123</th>
                                <th>三星<br>立三柱</th>
                                <th>二星<br>立二柱</th>
                                <th>號1</th>
                                <th>號2</th>
                                <th>號3</th>
                                <th>號4</th>
                                <th>號5</th>
                                <th>星計數</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="is_loading">
                                <td colspan="10">
                                    <h3>載入中...</h3>
                                </td>
                            </tr>
                            <tr
                                v-for="daily_cash in daily_cashs"
                                :key="daily_cash.id">
                                <td style="width: 110px;height: 50px;white-space: nowrap;">
                                    {{ daily_cash.lottery_index }}期<br>
                                    {{ daily_cash.lottery_date }}
                                </td>
                                <td style="width: 110px;height: 50px;white-space: nowrap;" :class="{
                                    is_conformed: daily_cash.is_conformed
                                }">
                                    {{ daily_cash.is_conformed ? "是" : "否" }}
                                    <br>
                                    <span v-if="daily_cash.is_conformed">
                                        隔{{ daily_cash.conformed_counter }}期
                                    </span>
                                </td>
                                <td style="width: 110px;height: 50px;white-space: nowrap;" :class="{
                                    is_thirteen_conformed: daily_cash.is_thirteen_conformed
                                }">
                                    {{ daily_cash.is_thirteen_conformed ? "是" : "否" }}
                                    <br>
                                    <span v-if="daily_cash.is_thirteen_conformed">
                                        隔{{ daily_cash.thirteen_conformed_counter }}期
                                    </span>
                                </td>
                                <td style="width: 110px;height: 50px;white-space: nowrap;" :class="{
                                    is_twenty_conformed: daily_cash.is_twenty_conformed,
                                    danger: two_star_1_count(daily_cash) == 1 || two_star_2_count(daily_cash) == 1
                                }">
                                    {{ daily_cash.is_twenty_conformed ? "是" : "否" }}
                                    <br>
                                    <span v-if="daily_cash.is_twenty_conformed">
                                        隔{{ daily_cash.twenty_conformed_counter }}期
                                    </span>
                                </td>
                                <td style="width: 50px;height: 50px;position: relative;">
                                    <span class="top dot" :class="{'green': (daily_cash.n1 >= 1 && daily_cash.n1 <= 13), 'red': (daily_cash.n1 >= 14 && daily_cash.n1 <= 26), 'blue': (daily_cash.n1 >= 27 && daily_cash.n1 <= 39)}"></span>
                                    {{ daily_cash.n1 }}
                                    <span class="bottom dot" :class="{'blueviolet': (daily_cash.n1 >= 1 && daily_cash.n1 <= 19), 'brown': (daily_cash.n1 >= 20 && daily_cash.n1 <= 39)}"></span>
                                </td>
                                <td style="width: 50px;height: 50px;position: relative;">
                                    <span class="top dot" :class="{'green': (daily_cash.n2 >= 1 && daily_cash.n2 <= 13), 'red': (daily_cash.n2 >= 14 && daily_cash.n2 <= 26), 'blue': (daily_cash.n2 >= 27 && daily_cash.n2 <= 39)}"></span>
                                    {{ daily_cash.n2 }}
                                    <span class="bottom dot" :class="{'blueviolet': (daily_cash.n2 >= 1 && daily_cash.n2 <= 19), 'brown': (daily_cash.n2 >= 20 && daily_cash.n2 <= 39)}"></span>
                                </td>
                                <td style="width: 50px;height: 50px;position: relative;">
                                    <span class="top dot" :class="{'green': (daily_cash.n3 >= 1 && daily_cash.n3 <= 13), 'red': (daily_cash.n3 >= 14 && daily_cash.n3 <= 26), 'blue': (daily_cash.n3 >= 27 && daily_cash.n3 <= 39)}"></span>
                                    {{ daily_cash.n3 }}
                                    <span class="bottom dot" :class="{'blueviolet': (daily_cash.n3 >= 1 && daily_cash.n3 <= 19), 'brown': (daily_cash.n3 >= 20 && daily_cash.n3 <= 39)}"></span>
                                </td>
                                <td style="width: 50px;height: 50px;position: relative;">
                                    <span class="top dot" :class="{'green': (daily_cash.n4 >= 1 && daily_cash.n4 <= 13), 'red': (daily_cash.n4 >= 14 && daily_cash.n4 <= 26), 'blue': (daily_cash.n4 >= 27 && daily_cash.n4 <= 39)}"></span>
                                    {{ daily_cash.n4 }}
                                    <span class="bottom dot" :class="{'blueviolet': (daily_cash.n4 >= 1 && daily_cash.n4 <= 19), 'brown': (daily_cash.n4 >= 20 && daily_cash.n4 <= 39)}"></span>
                                </td>
                                <td style="width: 50px;height: 50px;position: relative;">
                                    <span class="top dot" :class="{'green': (daily_cash.n5 >= 1 && daily_cash.n5 <= 13), 'red': (daily_cash.n5 >= 14 && daily_cash.n5 <= 26), 'blue': (daily_cash.n5 >= 27 && daily_cash.n5 <= 39)}"></span>
                                    {{ daily_cash.n5 }}
                                    <span class="bottom dot" :class="{'blueviolet': (daily_cash.n5 >= 1 && daily_cash.n5 <= 19), 'brown': (daily_cash.n5 >= 20 && daily_cash.n5 <= 39)}"></span>
                                </td>
                                <td style="width: 110px;height: 50px;white-space: nowrap;">
                                    <span class="green">{{ three_star_1_count(daily_cash) }}</span> / 
                                    <span class="red">{{ three_star_2_count(daily_cash) }}</span> / 
                                    <span class="blue">{{ three_star_3_count(daily_cash) }}</span>
                                    <hr style="margin: 0.2rem 0;">
                                    <span class="blueviolet">{{ two_star_1_count(daily_cash) }}</span> / 
                                    <span class="brown">{{ two_star_2_count(daily_cash) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "Dashboard",
    data: () => {
        return {
            dashboard_data: {},
            is_loading: false
        }
    },
    created() {
        console.log('Dashboard created')
        this.is_loading = true;
        axios
            .get('/dashboard/index.json')
            .then(response => {
                this.is_loading = false;
                this.dashboard_data = response.data
            })
    },
    methods: {
        type_tr_click(event) {
            var target_type = $(event.currentTarget).data("type");
            console.log(target_type);
            $('.type-tr').removeClass("active");
            $('.type-tr[data-type="' + target_type + '"]').toggleClass("active");
        },
        load_data(limit) {
            this.dashboard_data.daily_cashs = [];
            this.is_loading = true;
            this.$nextTick(() => {
                axios.get('/dashboard/index.json?limit=' + limit)
                    .then(response => {
                        this.is_loading = false;
                        this.dashboard_data = response.data
                    })
            })

        },
        three_star_1_count(daily_cash) {
            var three_star_1_count = 0;
            if(daily_cash.n1 >= 1 && daily_cash.n1 <= 13) three_star_1_count += 1;
            if(daily_cash.n2 >= 1 && daily_cash.n2 <= 13) three_star_1_count += 1;
            if(daily_cash.n3 >= 1 && daily_cash.n3 <= 13) three_star_1_count += 1;
            if(daily_cash.n4 >= 1 && daily_cash.n4 <= 13) three_star_1_count += 1;
            if(daily_cash.n5 >= 1 && daily_cash.n5 <= 13) three_star_1_count += 1;
            return three_star_1_count;
        },
        three_star_2_count(daily_cash) {
            var three_star_2_count = 0;
            if(daily_cash.n1 >= 14 && daily_cash.n1 <= 26) three_star_2_count += 1;
            if(daily_cash.n2 >= 14 && daily_cash.n2 <= 26) three_star_2_count += 1;
            if(daily_cash.n3 >= 14 && daily_cash.n3 <= 26) three_star_2_count += 1;
            if(daily_cash.n4 >= 14 && daily_cash.n4 <= 26) three_star_2_count += 1;
            if(daily_cash.n5 >= 14 && daily_cash.n5 <= 26) three_star_2_count += 1;
            return three_star_2_count;
        },
        three_star_3_count(daily_cash) {
            var three_star_3_count = 0;
            if(daily_cash.n1 >= 27 && daily_cash.n1 <= 39) three_star_3_count += 1;
            if(daily_cash.n2 >= 27 && daily_cash.n2 <= 39) three_star_3_count += 1;
            if(daily_cash.n3 >= 27 && daily_cash.n3 <= 39) three_star_3_count += 1;
            if(daily_cash.n4 >= 27 && daily_cash.n4 <= 39) three_star_3_count += 1;
            if(daily_cash.n5 >= 27 && daily_cash.n5 <= 39) three_star_3_count += 1;
            return three_star_3_count;
        },
        two_star_1_count(daily_cash) {
            var two_star_1_count = 0;
            if(daily_cash.n1 >= 1 && daily_cash.n1 <= 19) two_star_1_count += 1;
            if(daily_cash.n2 >= 1 && daily_cash.n2 <= 19) two_star_1_count += 1;
            if(daily_cash.n3 >= 1 && daily_cash.n3 <= 19) two_star_1_count += 1;
            if(daily_cash.n4 >= 1 && daily_cash.n4 <= 19) two_star_1_count += 1;
            if(daily_cash.n5 >= 1 && daily_cash.n5 <= 19) two_star_1_count += 1;
            return two_star_1_count;
        },
        two_star_2_count(daily_cash) {
            var two_star_2_count = 0;
            if(daily_cash.n1 >= 20 && daily_cash.n1 <= 39) two_star_2_count += 1;
            if(daily_cash.n2 >= 20 && daily_cash.n2 <= 39) two_star_2_count += 1;
            if(daily_cash.n3 >= 20 && daily_cash.n3 <= 39) two_star_2_count += 1;
            if(daily_cash.n4 >= 20 && daily_cash.n4 <= 39) two_star_2_count += 1;
            if(daily_cash.n5 >= 20 && daily_cash.n5 <= 39) two_star_2_count += 1;
            return two_star_2_count;
        },
    },
    computed: {
        daily_cashs() {
            return this.dashboard_data.daily_cashs;
        },
        two_star_50_probability() {
            var count_of_50 = 0;
            this.daily_cashs.forEach((value, index) => {
                if(this.two_star_1_count(value) == 0 || this.two_star_2_count(value) == 0) {
                    count_of_50 += 1;
                }
            });
            return count_of_50;
        },
        two_star_41_probability() {
            var count_of_41 = 0;
            this.daily_cashs.forEach((value, index) => {
                if(this.two_star_1_count(value) == 1 || this.two_star_2_count(value) == 1) {
                    count_of_41 += 1;
                }
            });
            return count_of_41;
        }
        ,
        two_star_32_probability() {
            var count_of_32 = 0;
            this.daily_cashs.forEach((value, index) => {
                if(this.two_star_1_count(value) == 2 || this.two_star_2_count(value) == 2) {
                    count_of_32 += 1;
                }
            });
            return count_of_32;
        }
    }
}
</script>

<style lang="scss" scoped>
.type-tr {
    cursor: pointer;
}
.type-tr.active td {
    background-color: #38ff52 !important;
}
.table-container {
    height: 33vh;
    overflow-y: auto;
    background-color: #FAFAFA;
    margin-bottom: 5px;
}
.green {
    color: green;
}
.red {
    color: red;
}
.blue {
    color: blue;
}
.blueviolet {
    color: blueviolet;
}
.brown {
    color: brown;
}
.table:not(.table-bordered) td{
    border: none;
}
td, th {
    text-align: center;
    vertical-align: middle;
}
td.is_conformed {
    background-color: yellow;
}
td.is_thirteen_conformed {
    background-color: orange;
}
td.is_twenty_conformed {
    background-color: #b1ff38;
}
td.is_twenty_conformed.danger {
    background-color: red !important;
}
.dot.top {
    display: inline-block;
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 5px;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.dot.bottom {
    display: inline-block;
    width: 5px;
    height: 5px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.dot.green {
    background-color: green;
}
.dot.red {
    background-color: red;
}
.dot.blue {
    background-color: blue;
}
.dot.blueviolet {
    background-color: blueviolet;
}
.dot.brown {
    background-color: brown;
}
</style>
