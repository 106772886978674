import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Dashboard from '../components/dashboard.vue'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
    const app = new Vue({
        el: '#vue-main',
        components: { Dashboard },
        data: () => {
            return {
            }
        },
        created() {
            console.log('VueMain created')
        },
        before_mount() {
            this.$originElement = this.$el.outerHTML
        },
        destroyed() {
            this.$el.outerHTML = this.$originElement
        }
    })
})

document.addEventListener("turbolinks:before-cache", () => {
    app.$destroy();
})
